<template>
    <div class="lines">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">Customer Lines</a></li>
            </ul>
        </nav>
        <p class="total-results">{{totalResults}} Results</p>

        <div class="field is-grouped">
            <h1 class="title">Customer Lines</h1>
            <b-dropdown v-show="showAddLineDropdownTrigger" @active-change="dropdownActiveChange">
                <template #trigger>
                    <div class="button is-primary is-small is-rounded p-3 mt-1 ml-3" aria-haspopup="true" aria-controls="dropdown-menu1" :disabled="isLoading">
                        <span class="icon is-small"><font-awesome-icon icon="plus" /></span>
                    </div>
                </template>
            </b-dropdown>
            <div v-show="!showAddLineDropdownTrigger" class="button is-primary is-small is-rounded p-3 mt-1 ml-3" aria-haspopup="true" aria-controls="dropdown-menu1" :disabled="isLoading">
                <span class="icon is-small"><font-awesome-icon icon="plus" /></span>
            </div>
            <div class="block p-2">
                <div :class="linesDropdownActive ? 'dropdown is-active' : 'dropdown'">
                    <div class="dropdown-menu" id="dropdown-menu1" role="menu">
                        <div class="dropdown-content">
                            <div class="dropdown-item">
                                <router-link class="subtitle is-6" to="line">
                                    Add One Line
                                </router-link>
                            </div>
                            <div class="dropdown-item" @click="()=>{importLinesModalActive = !importLinesModalActive}">
                                <a class="subtitle is-6"> Add Multiple </a>
                            </div>
                            <div class="dropdown-item" @click="()=>{permissionsModalActive = !permissionsModalActive}">
                                <a class="subtitle is-6">Update Line Permissions</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="field has-addons mb-0">
            <div class="control is-expanded has-icons-right mr-4">
                <input id="searchBox" ref="searchBox" class="input" type="text" placeholder="Search customer lines..."
                       v-model="lineSearch" @keyup.enter="newSearch" autocomplete="off">
                <span v-if="lineSearch" class="icon is-small is-right">
                    <a class="delete" @click="clearSearchTerm"></a>
                </span>
            </div>
            <div class="control mr-4">
                <a class="button is-primary" id="search-button" @click="newSearch" :disabled="isLoading">
                    Search
                </a>
            </div>
            <FilterDropdown :displayAccount=true
                            :defaultAccountPublicId=accountPublicId
                            :defaultLineIntegration=lineIntegration
                            :defaultTextingService=textingService
                            :filtersApplied=filtersApplied
                            :displayTextingServices=true
                            :displayLineIntegrations=true
                            :displayLineIsEnabled="true"
                            @accountSelected="accountSelected"
                            @textingServiceSelected="textingServiceSelected"
                            @lineIntegrationSelected="lineIntegrationSelected"
                            @lineIsEnabledSelected="lineIsEnabledSelected"
                            @clearFilters="clearFilters" />
            <div class="control mx-4">
                <b-button class="button is-primary" id="export-button" @click="exportLines()" :loading="isExporting">
                    Export
                </b-button>
            </div>
        </div>

        <Loading v-if="isLoading" />
        <div v-else class="table-container">
            <table class="table is-striped is-fullwidth has-top-border has-bottom-border">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th><abbr title="Public Id">PId</abbr></th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Enabled</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(line, index) in customerLines" :key="'line' + index">
                        <td> {{ line.lineId }}</td>
                        <td>
                            <router-link :to="'/line/' + line.publicLineId">{{ line.publicLineId }}</router-link>
                        </td>
                        <td>
                            <router-link :to="'/line/' + line.publicLineId">{{ line.lineName }}</router-link>
                        </td>
                        <td> {{ line.phoneNumber }}</td>
                        <td> {{ line.isEnabled}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <Pagination :isLoading="isLoading" :currentPage="currentPage" :totalPages="totalPages" v-on:setPage="n => setPage(n)" />

        <CustomerLineImportModal v-show="importLinesModalActive"
                                 @close="importLinesModalActive = false" />

        <AccountLineProductPermissionsModal v-show="permissionsModalActive"
                                            @close="$event => permissionsModalActive = false"
                                            @accountLinePermissionsSelected="saveAccountLineProductPermissions"
                                            @error="error" />

        <a ref="exportFile" hidden></a>
    </div>
</template>

<script>
    import { securePost } from '../secureFetch.js';

    import AccountLineProductPermissionsModal from '../components/AccountLineProductPermissionsModal.vue';
    import Pagination from '../components/Pagination';
    import Loading from '../components/Loading';
    import FilterDropdown from '../components/FilterDropdown'
    import CustomerLineImportModal from '../components/CustomerLineImportModal';
    import { textingServicesDictionary, lineTypeDictionary } from '../dictionaries.js';

    export default {
        name: 'Lines',
        components: {
            AccountLineProductPermissionsModal,
            Pagination,
            Loading,
            FilterDropdown,
            CustomerLineImportModal
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            },
            lineSearch: {
                set(value) {
                    this.$store.commit('updateLineSearchFilter', value);
                },
                get() {
                    return this.$store.state.lineFilters.searchTerm;
                }
            },
            currentPage: {
                set(value) {
                    this.$store.commit('updateLinePageFilter', value);
                },
                get() {
                    return this.$store.state.lineFilters.page;
                }
            },
            accountPublicId: {
                set(value) {
                    this.$store.commit('updateLineAccountIdFilter', value);
                },
                get() {
                    return this.$store.state.lineFilters.accountPublicId;
                }
            },
            textingService: {
                set(value) {
                    this.$store.commit('updateLineTextingServiceFilter', value)
                },
                get() {
                    return this.$store.state.lineFilters.textingService;
                }
            },
            lineIntegration: {
                set(value) {
                    this.$store.commit('updateLineIntegrationFilter', value);
                },
                get() {
                    return this.$store.state.lineFilters.integration;
                }
            },
            lineIsEnabled: {
                set(value) {
                    this.$store.commit('updateLineIsEnabledFilter', value);
                },
                get() {
                    return this.$store.state.lineFilters.isEnabled;
                }
            },
            filtersApplied: function () {
                var filters = 0;

                if (this.$store.state.lineFilters.accountPublicId != null) {
                    filters++;
                }
                
                if (this.$store.state.lineFilters.integration != null) {
                    filters++;
                }
                
                if (this.$store.state.lineFilters.textingService != null) {
                    filters++;
                }

                if (this.$store.state.lineFilters.isEnabled != null) {
                    filters++;
                }

                return filters;
            }
        },
        data() {
            return {
                isLoading: true,
                errorMessage: null,
                customerLines: [],
                resultsPerPage: 25,
                totalPages: 0,
                totalResults: 0,
                linesDropdownActive: false,
                importLinesModalActive: false,
                showAddLineDropdownTrigger: true,
                textingServiceSelectedName: null,
                lineIntegrationSelectedName: null,
                isExporting: false,
                permissionsModalActive: false,
                productAccess: null,
                lineIsEnabledDictionary: { 1: true, 2: false }
            }
        },
        mounted() {
            this.searchCustomerLines();
        },
        methods: {
            error(error) {
                this.errorMessage = error;
            },
            searchCustomerLines() {
                this.isLoading = true;

                this.customerLines = [];
                this.totalPages = 0;
                this.totalResults = 0;
                this.errorMessage = null;

                var postBody = {
                    searchTerm: this.lineSearch,
                    serviceTypeCode: this.textingService,
                    integrationTypeCode: this.lineIntegration,
                    page: this.currentPage,
                    resultsPerPage: this.resultsPerPage,
                    accountPublicId: this.accountPublicId,
                    isEnabled: this.lineIsEnabled
                };

                securePost(this.hostUrl + "v1/line/search-customer-lines", postBody)
                    .then(data => {
                        this.isLoading = false;
                        if (data && data.success) {
                            this.customerLines = data.customerLines;
                            this.currentPage = data.currentPage;
                            this.totalPages = data.totalPages;
                            this.totalResults = data.totalResults;
                        }
                        else {
                            this.errorMessage = "Error Retrieving Accounts: " + data.message;
                        }
                    });
            },
            newSearch() {
                this.currentPage = 0;
                this.searchCustomerLines();
            },
            clearSearchTerm() {
                this.lineSearch = '';
                this.currentPage = 0;
                this.searchCustomerLines();
                this.$refs.searchBox.focus();
            },
            setPage(page) {
                this.currentPage = page;
                this.searchCustomerLines();
            },
            clearFilters() {
                this.accountPublicId = null;
                this.textingService = null;
                this.lineIntegration = null;
                this.textingServiceSelectedName = null;
                this.lineIntegrationSelectedName = null;
                this.lineIsEnabled = null;
                this.newSearch();
            },
            accountSelected(publicId) {
              this.accountPublicId = publicId;
              this.newSearch();
            },
            dropdownActiveChange() {
                this.$nextTick(() => {
                    this.linesDropdownActive = !this.linesDropdownActive;
                });
                this.showAddLineDropdownTrigger = !this.showAddLineDropdownTrigger;
            },
            textingServiceSelected(service) {
                this.textingService = service.code;
                this.textingServiceSelectedName = service.name;
                this.newSearch();
            },
            lineIntegrationSelected(integration) {
                this.lineIntegration = integration.code;
                this.lineIntegrationSelectedName = integration.name;
                this.newSearch();
            },
            lineIsEnabledSelected(isEnabled) {
                this.lineIsEnabled = isEnabled == null ? null : this.lineIsEnabledDictionary[isEnabled];
                this.newSearch();
            },
            async exportLines() {
                this.isExporting = true;
                let customerExportLines;
                var postBody = {
                    searchTerm: this.lineSearch,
                    serviceTypeCode: this.textingService,
                    integrationTypeCode: this.lineIntegration,
                    page: 0,
                    resultsPerPage: 10000,
                    accountPublicId: this.accountPublicId,
                    isEnabled: this.lineIsEnabled
                };
                await securePost(this.hostUrl + "v1/line/search-customer-lines", postBody)
                    .then(data => {
                        if (data && data.success) {
                            customerExportLines = data.customerLines;
                        }
                        else {
                            this.errorMessage = "Error Retrieving Accounts: " + data.message;
                        }
                    });
                let csvFile = this.formatFile(customerExportLines);
                const anchor = this.$refs.exportFile;
                const encodedCsv = encodeURIComponent(csvFile);
                anchor.href = `data:text/csv;charset=utf-8,${encodedCsv}`;
                anchor.target = "_blank";
                let downloadFileName = `Lines`;
                if (this.lineSearch.length > 0) {
                    downloadFileName += `_${this.lineSearch}`;
                }
                if (this.accountPublicId != null) {
                    downloadFileName += `_${this.accountPublicId}`;
                }
                if (this.textingServiceSelectedName != null) {
                    downloadFileName += `_${this.textingServiceSelectedName}`;
                }
                if (this.lineIntegrationSelectedName != null) {
                    downloadFileName += `_${this.lineIntegrationSelectedName}`;
                }
                if (this.lineIsEnabled != null) {
                    downloadFileName += `_isEnabled=${this.lineIsEnabled}`;
                }
                downloadFileName += ".csv";
                anchor.download = downloadFileName;
                anchor.click();
                this.isExporting = false;
            },
            formatFile(customerExportLines) {
                let csv = "Line Id,Customer Line Id,Service Key,Service User,Service Password,Line Type,Line Name,Texting Service,Phone Number,Status\n";
                let status = null;
                customerExportLines.forEach(line => {
                    if (line.isEnabled) {
                        status = "Enabled";
                    }
                    else {
                        status = "Disabled";
                    }
                    csv += `${line.lineId},${line.id},${this.nullCheck(line.serviceKey)},${this.nullCheck(line.serviceUser)},${this.nullCheck(line.servicePassword)},${lineTypeDictionary[line.lineType]},${line.lineName},${textingServicesDictionary[line.service]},${line.phoneNumber},${status}`;
                    csv += "\n";
                });
                return csv;
            },
            nullCheck(check) {
                return check || "";
            },
            async saveAccountLineProductPermissions(accountLinePermissions) {
                await securePost(this.hostUrl + "v1/line/bulk-line-permissions", accountLinePermissions)
                .then(data => {
                    if (!data || !data.success) {
                        this.errorMessage = "Failed to save account line product permissions.";
                    }
                })
            },
        }
    }
</script>

<style scoped>
    #search-button {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    #export-button {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .total-results {
        float: right;
    }
</style>