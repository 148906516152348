export const textingServicesDictionary = { 
    1: 'Zipwhip', 
    2: 'Bandwidth', 
    3: 'Sinch', 
    4: 'RingCentral', 
    5: 'Twilio' 
};
export const lineTypeDictionary = { 
    0: "Unknown", 
    1: 'Toll Free', 
    2: 'Domestic Long Code', 
    3: 'International Long Code', 
    4: 'Domestic Short Code', 
    5: 'International Short Code', 
    6: 'Alphanumeric' 
};