<template>
    <div class="modal is-active">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <h1 class="title">Account Lines Product Permissions</h1>
                <button class="delete" @click="close" aria-label="close"></button>
            </header>
            <section class="modal-card-body" style="height: 800px;">
                <div class="columns is-multiline">
                    <div class="column is-full">
                        <p class="content">Select an account</p>
                        <p v-if="accountId" class="content">{{ accountId }}</p>
                        <AccountDropdown :isLeft="true" @accountSelected="accountSelected" />
                    </div>
                    <div v-if="accountId" class="column is-full">
                        <p class="content">Select lines (default is all account lines)</p>
                        <div>
                            <b-tag v-for="customerLine in customerLinesSelected" :key="customerLine.id"> {{customerLine.lineName}} </b-tag>
                        </div>
                        <div class="columns is-multiline">
                            <div class="field column is-narrow">
                                <b-dropdown
                                    aria-role="list"
                                    scrollable
                                    max-height="200"
                                    v-model="customerLinesSelected"
                                    multiple>
                                    <template #trigger>
                                        <b-button
                                            type="is-primary"
                                            icon-right="menu-down"
                                            @click="customerLineDropdownSelected">
                                            Selected ({{ customerLinesSelected.length }})
                                        </b-button>
                                    </template>

                                    <b-dropdown-item custom aria-role="listitem">
                                        <b-input v-model="customerLineDropdownFilter" placeholder="search" expanded/>
                                    </b-dropdown-item>

                                    <b-dropdown-item
                                        v-for="customerLine in customerLinesFiltered"
                                        :key="customerLine.lineName"
                                        :value="customerLine"
                                    >
                                        {{customerLine.lineName}} - {{customerLine.phoneNumber}}
                                    </b-dropdown-item>

                                </b-dropdown>
                            </div>
                        </div>
                        <ProductPermissions 
                            :productAccess="productAccess" 
                            @accessUpdated="setProductAccess"  
                            :expandView="true"
                        />
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-primary" :disabled="!accountId" @click="saveProductPermissions">Save changes</button>
                <button class="button is-primary" @click="close">Cancel</button>
            </footer>
        </div>
    </div>
</template>

<script>
    import { securePost } from '../secureFetch.js';
    import AccountDropdown from './AccountDropdown.vue';
    import ProductPermissions from './ProductPermissions.vue'; 

    export default {
        name: 'AccountLineProductPermissionsModal',
        data() {
            return {
                customerLines: [],
                customerLinesSelected: [],
                customerLineDropdownFilter: "",
                customerLineDropdownActive: false,
                firstSearch: true,
                accountId: null,
                productAccess: null,
                errorMessage: "",
            }
        },
        components: {
            AccountDropdown,
            ProductPermissions,
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            },
            customerLinesFiltered: function() {
                var customerLineFilter = "";
                if (this.customerLineDropdownFilter) {
                    customerLineFilter = this.customerLineDropdownFilter;
                }
                return this.customerLines.filter(function (customerLine) {
                    return customerLine.lineName.toUpperCase().match(customerLineFilter.toUpperCase()) 
                        || customerLine.phoneNumber.includes(customerLineFilter)
                });
            }
        },
        watch: {
            errorMessage(value) {
                this.$emit("error", value);
            },
        },
        methods: {
            delayCloseCustomerLineDropdown() {
                setTimeout(() => this.customerLineDropdownActive = false, 500);
            },
            customerLineDropdownSelected() {
                if(this.firstSearch){
                    this.firstSearch = false;
                    this.searchCustomerLines()
                }
            },
            saveProductPermissions() {
                this.$emit('accountLinePermissionsSelected', { accountId: this.accountId, lineIds: this.customerLinesSelected.map(function (line) { return line.lineId }), productAccess: this.productAccess });
                this.customerLinesSelected = [];
                this.accountId = null;
                this.customerLines = [];
                this.customerLineDropdownFilter = "";
                this.productAccess = null;
                this.$emit('close', true);
            },
            searchCustomerLines() {
                this.customerLines = [];
                this.errorMessage = null;

                var postBody = {
                    accountId: this.accountId,
                    page: 0,
                    resultsPerPage: 9999
                };

                securePost(this.hostUrl + "v1/line/search-customer-lines", postBody)
                    .then(data => {
                        if (data && data.success) {
                            this.customerLines = data.customerLines.sort((a, b) => a.lineName.localeCompare(b.lineName));
                        }
                        else {
                            this.errorMessage = "Error Retrieving CustomerLines: " + data.message;
                        }
                });
            },
            close(){
                this.customerLinesSelected = [];
                this.$emit('close', true);
            },
            accountSelected(accountId) {
                this.accountId = accountId;
                this.customerLinesSelected = [];
                this.customerLines = [];
                this.customerLineDropdownFilter = "";
                this.productAccess = null;
                this.searchCustomerLines();
            },
            setProductAccess(productAccess) {
                this.productAccess = productAccess;
            },
        },
    };
</script>

<style scoped>
    .dropdown-content {
        max-height: 800em;
        overflow: auto;
    }
    .modal-card {
        max-width: 820px;
        width: fit-content;
    }
</style>