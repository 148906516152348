<template>
  <div class="modal-background">
    <div v-show="!isResultView">
      <div class="modal-card pt-6" id="import-modal">
        <header class="modal-card-head">
          <p class="modal-card-title">Create Multiple Lines</p>
          <button class="delete is-medium" @click="close()" aria-label="close"></button>
        </header>
        <section class="modal-card-body" id="import-modal-body">
          <div class="content">
            <p> You can upload multiple lines to an account using a CSV import. Select the Line Type, Texting Service, and Integration Type, then download the import template. All columns in the import template are required. NOTE: Since this is a CSV file, do not use commas in the Line name. </p>
            <p> What is selected will apply to all of the lines in your upload. </p>
          </div>
          <label class="label">Account Id</label>
          <div class="field has-addons">
            <div class="control is-expanded">
              <input class="input" type="text" placeholder="Account id..." v-model="accountId">
            </div>
            <AccountDropdown @accountDetailsSelected="accountSelected"/>
          </div>
          
          <div class="columns is-multiline">
            <div class="field column">
              <label class="label">Line Type</label>
              <b-dropdown v-model="lineType" :disabled="isLoading">
                <template #trigger>
                  <div class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span v-if="!lineType">Select</span>
                    <span>{{ lineTypeDictionary[lineType] }}</span>
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fa', 'angle-down']" />
                    </span>
                  </div>
                </template>

                <b-dropdown-item 
                  v-for="(index, lineKey) in lineTypeDictionaryFiltered" 
                  :key="'lineType' + index"
                  :value="{ lineKey }"
                  aria-role="listitem"
                  @click="setLineType(lineKey)">
                  {{ lineTypeDictionary[lineKey] }}
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <div class="field column">
              <label class="label">Texting Service</label>
              <b-dropdown v-model="service" :disabled="isLoading">
                <template #trigger>
                  <div class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span v-if="!service">Select</span>
                    <span>{{ serviceDictionary[service] }}</span>
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fa', 'angle-down']" />
                    </span>
                  </div>
                </template>

                <b-dropdown-item 
                  v-for="(index, serviceKey) in serviceDictionaryFiltered" 
                  :key="'service' + index"
                  :value="{ serviceKey }"
                  aria-role="listitem"
                  @click="setService(serviceKey)">
                  {{ serviceDictionary[serviceKey] }}
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <div class="field column">
              <label class="label">Integration Type</label>
              <b-dropdown
                v-model="integrationType"
                aria-role="list">
                <template #trigger>
                  <div class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span v-if="!integrationType">Select</span>
                    <span>{{ integrationDictionary[integrationType] }}</span>
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fa', 'angle-down']" />
                    </span>
                  </div>
                </template>
                <b-dropdown-item
                  v-for="(index, integrationTypeKey) in integrationDictionaryFiltered" 
                  :key="'integrationType' + index"
                  :value="integrationTypeKey"
                  aria-role="listitem"
                  @click="setIntegrationType(integrationTypeKey)">
                  {{integrationDictionary[integrationTypeKey]}}
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <div v-if="this.isNiceIntegration" class="field column">
              <label class="label">Routing Method</label>
              <b-dropdown v-model="routingMethod" :disabled="isLoading">
                <template #trigger>
                  <div class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span v-if="!routingMethod">Select</span>
                    <span>{{ routingMethodDictionary[routingMethod] }}</span>
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fa', 'angle-down']" />
                    </span>
                  </div>
                </template>
                <b-dropdown-item 
                    v-for="(index, routingMethodKey) in routingMethodDictionary" 
                    :key="'routingMethod' + index"
                    :value="{ routingMethodKey }"
                    aria-role="listitem"
                    @click="setRoutingMethod(routingMethodKey)">
                    {{ routingMethodDictionary[routingMethodKey] }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          
          <button class="button is-primary" :disabled="!canDownloadTemplate" @click="downloadTemplate()">Download Template</button>

          <div class="label mt-5"> Required Fields </div>
          <div class="content">
            <ul class="list">
              <li class="list-item">Line Number</li>
              <li class="list-item">Line Name</li>
              <li class="list-item" v-if="this.isZipwhipService || this.isTwilioService">Service Key</li>
              <li class="list-item" v-if="this.isTwilioService">Service User</li>
              <li class="list-item" v-if="this.isTwilioService">Service Password</li>
              <li class="list-item" v-if="this.isNiceIntegration && this.isChatRouting">Point of Contact Id</li>
              <li class="list-item" v-if="this.isNiceIntegration && this.isNiceDFORouting">Webhook URL</li>
              <li class="list-item" v-if="this.isNiceIntegration && this.isNiceDFORouting">Webhook Auth Type</li>
              <li class="list-item" v-if="this.isNiceIntegration && this.isNiceDFORouting">Webhook Auth Value</li>
              <li class="list-item" v-if="this.isGenesysCCaaSIntegration">Chat Deployment ID</li>
              <li class="list-item" v-if="this.isGenesysCCaaSIntegration">Queue ID</li>
              <li class="list-item" v-if="(this.isNiceIntegration && this.isChatRouting) || this.isGenesysCCaaSIntegration">Hide Attachment From Url</li>
            </ul>
          </div>
          <div class="file has-name is-primary is-fullwidth">
            <label class="file-label">
              <input class="file-input" type="file" name="lineImportFile" @change="setValue($event)">
              <span class="file-cta">
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fa', 'upload']" />
                </span>
                <span class="file-label ml-3">
                  Upload CSV
                </span>
              </span>
              <span class="file-name">
                {{ fileName || 'No file chosen...' }}
              </span>
            </label>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button :class="isLoading ? 'button is-primary is-loading' : 'button is-primary'" :disabled="!canUploadTemplate" @click="uploadLines()">Upload Lines</button>
          <button class="button is-primary" @click="close()">Cancel</button>
        </footer>
      </div>
    </div>
    <div v-show="isResultView">
      <div class="modal-card pt-6" id="import-modal">
        <header class="modal-card-head">
          <p class="modal-card-title">Results</p>
          <button class="delete" @click="close()" aria-label="close"></button>
        </header>
        <section class="modal-card-body" id="import-modal-body">
          <div v-if="linesSuccess && !logHasErrors" class="message is-success">
            <div class="message-header">
              Success
            </div>
            <div class="message-body">
              <div class="content pb-2">
                <p><strong>Lines Successfully created: {{ this.linesCreated }}</strong></p>
                <p v-if="'{{integrationDictionary[integrationType]}}' == 'Nice'"><strong></strong>ExternalLinesCreated: {{ this.externalLinesCreated }}</p>
                <p><stron>Lines Failed to create: {{ this.linesFailed }}</stron></p>
                <p v-if="this.errorMessage">Service Message: {{ this.errorMessage }}</p>
              </div>
              <button class="button is-success" @click="downloadLogFile()">
                <span class="icon">
                  <font-awesome-icon :icon="['fa', 'download']" />
                </span>
                <span>Download Log File</span>
              </button>
            </div>
          </div>
          <div v-else-if="linesSuccess && logHasErrors" class="message is-warning">
            <div class="message-header">
              Success With Errors
            </div>
            <div class="message-body">
              <div class="content pb-2">
                <p><strong>Lines Successfully created: {{ this.linesCreated }}</strong></p>
                <p><strong>Lines Failed to create: {{ this.linesFailed }}</strong></p>
                <p>Warning: Completed with errors. Check log file.</p>
                <p v-if="this.errorMessage">Service Message: {{ this.errorMessage }}</p>
              </div>
              <button class="button is-warning" @click="downloadLogFile()">
                <span class="icon">
                  <font-awesome-icon :icon="['fa', 'download']" />
                </span>
                <span>Download Log File</span>
              </button>
            </div>
          </div>
          <div v-else class="message is-danger">
            <div class="message-header">
              Import Failure
            </div>
            <div class="message-body">
              <div class="content pb-2">
                <p><strong>Lines Successfully created: {{ this.linesCreated }}</strong></p>
                <p><strong>Lines Failed to create: {{ this.linesFailed }}</strong></p>
                <p>{{ this.errorMessage }}</p>
              </div>
              <div v-if="this.logFile">
                <button class="button is-danger" @click="downloadLogFile()">
                  <span class="icon">
                    <font-awesome-icon :icon="['fa', 'download']" />
                  </span>
                  <span>Download Log File</span>
                </button>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="closeResults()">Upload More Lines</button>
          <button class="button is-primary" @click="close()">Close</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
  import { secureForm } from '../secureFetch.js';
  import AccountDropdown from './AccountDropdown';

  export default {
  name: "CustomerLineImportModal",
  computed: {
    hostUrl: function () {
      return this.$store.state.hostUrl;
    },
    lineTypeDictionaryFiltered: function() {
      var typesFiltered = JSON.parse(JSON.stringify(this.lineTypeDictionary));
      delete typesFiltered[0];
      return typesFiltered;
    },
    serviceDictionaryFiltered: function() {
      var typesFiltered = JSON.parse(JSON.stringify(this.serviceDictionary));
      delete typesFiltered[0];
      delete typesFiltered[3];
      return typesFiltered;
    },
    integrationDictionaryFiltered: function() {
      var typesFiltered = JSON.parse(JSON.stringify(this.integrationDictionary));
      delete typesFiltered[0];
      return typesFiltered;
    },
    canUploadTemplate: function() {
      return (this.file != null && this.canDownloadTemplate);
    },
    fileName: function() {
      if (this.file) {
        return this.file.name;
      }
      return null;
    },
    isZipwhipService: function() {
      return parseInt(this.service) === 1;
    },
    isBandwidthService: function() {
      return parseInt(this.service) === 2;
    },
    isTwilioService: function() {
      return parseInt(this.service) === 5;
    },
    isNiceIntegration: function() {
      return parseInt(this.integrationType) === 3;
    },
    isGenesysCCaaSIntegration: function() {
      return parseInt(this.integrationType) === 4;
    },
    isChatRouting: function() {
      return parseInt(this.routingMethod) === 1;
    },
    isNiceDFORouting: function() {
      return parseInt(this.routingMethod) === 2;
    },
    canDownloadTemplate: function() {
     if (this.service == null || this.integrationType == null || (this.integrationType === 3 && this.routingMethod < 1)) {
      return false;
     }
     return true;
    }
  },
  data() {
    return {
      accountId: null,
      lineType: null,
      lineTypeDictionary: { 0: "Unknown", 1: 'Toll Free', 2: 'Domestic Long Code', 3: 'International Long Code', 4: 'Domestic Short Code', 5: 'International Short Code', 6: 'Alphanumeric' },
      service: null,
      serviceDictionary: { 0: "Unknown", 1: 'Zipwhip', 2: 'Bandwidth', 3: "Sinch", 4: "RingCentral", 5: "Twilio" },
      integrationType: null,
      integrationDictionary:{ 0: "Unknown", 1: 'Internal', 2: 'RingCentral', 3: 'Nice', 4: 'GenesysCCaaS', 5: 'GenesysUCaaS' },
      routingMethod: 1,
      routingMethodDictionary: { 0: 'Unknown', 1: 'Chat', 2: 'Nice DFO' },
      file: null,
      isLoading: false,
      isResultView: false,
      linesSuccess: false,
      linesCreated: 0,
      linesFailed: 0,
      logHasErrors: false,
      errorMessage: null,
      logFile: null,
      meevoTenantId: null,
    };
  },
  mounted() {
  },
  methods: {
    close() {
      this.accountId = null;
      this.lineType = null;
      this.service = null;
      this.integrationType = null;
      this.routingMethod = 1;
      this.file = null;
      this.isLoading = false;
      this.isResultView = false;
      this.linesSuccess = false;
      this.linesCreated = 0;
      this.linesFailed = 0;
      this.logHasErrors = false;
      this.errorMessage = null;
      this.logFile = null;
      this.meevoTenantId = null;
      this.$emit("close");
    },
    closeResults() {
      this.isResultView = false;
      this.linesSuccess = false;
      this.linesCreated = 0;
      this.linesFailed = 0;
      this.logHasErrors = false;
      this.errorMessage = null;
      this.logFile = null;
    },
    accountSelected(account) {
      this.accountId = account.id;
      this.meevoTenantId = account.meevoTenantId;
    },
    setLineType(lineType) {
      this.lineType = parseInt(lineType);
      this.lineTypeDropdownActive = false;
    },
    setIntegrationType(integrationType) {
      this.integrationType = parseInt(integrationType);
    },
    setRoutingMethod(routingMethod) {
      this.routingMethod = parseInt(routingMethod);
    },
    setService(service) {
      this.service = parseInt(service);
      this.serviceDropdownActive = false;
    },
    setValue(event) {
      this.file = event.target.files[0];
    },
    async uploadLines() {
      this.isLoading = true;

      var formData = new FormData();
      formData.append("AccountId", this.accountId);
      formData.append("LineType", this.lineType);
      formData.append("TextingService", this.service);
      formData.append("Integration.IntegrationType", this.integrationType);
      formData.append("Integration.RoutingMethod", this.routingMethod);
      formData.append("ImportFile", this.file);

      const result = await secureForm(this.hostUrl + "v1/line/create-lines", formData);

      this.isLoading = false;

      if (result != null) {
        this.linesSuccess = result.success;
        this.logHasErrors = result.logHasErrors;
        this.linesCreated = result.linesCreated;
        this.externalLinesCreated = result.externalLinesCreated;
        this.linesFailed = result.linesFailed;
        this.errorMessage = result.message;
        this.logFile = result.file;

        this.isResultView = true;
      }
    },
    downloadLogFile() {
      var link = document.createElement("a");
      link.download = this.logFile.fileDownloadName;
      link.href = `data:text/csv;base64,${this.logFile.fileContents}`;
      link.click();
    },
    downloadTemplate() {
      let headers = [];
      switch(this.service){
        case 1:  // ZIPWHIP
          headers.push("Line Number,Line Name,Service Key");
          break;
        case 2:  // BANDWIDTH
          headers.push("Line Number,Line Name");
          break;
        case 5:  // TWILIO
          headers.push("Line Number,Line Name,Service Key,Service User,Service Password");
          break;
      }
      switch (this.integrationType) {
        case 3:  // NICE
          if (this.routingMethod == 1) {    // CHAT
            headers.push("Point of Contact ID,Hide Attachment From URL");
          } else if (this.routingMethod == 2) { // DFO
            headers.push("Webhook URL,Webhook Auth Type,Webhook Auth Value");
          }
          break;
        case 4:  // GENESYS CCAAS
          headers.push("Chat Deployment ID,Queue ID,Hide Attachment From URL");
          break;
      }

      if (this.meevoTenantId) {
        headers.push("Meevo Location ID");
      }

      // vscode incorrectly identifies btoa as being deprecated, when it is only deprecated for nodejs.
      // see here for more info: https://github.com/microsoft/TypeScript/issues/45566
      let templateAsBase64 = headers.length > 0 ?
        window.btoa(headers.join(',')) :
        window.btoa('MAKE ALL SELECTIONS TO DOWNLOAD CORRECT TEMPLATE');

      var link = document.createElement("a");
      link.download = 'line_bulk_creation_CRUDy_template.csv';
      link.href = `data:text/csv;base64,${templateAsBase64}`;
      link.click();
    },
  },
  components: { AccountDropdown }
};
</script>

<style scoped>
  #import-modal {
    overflow:visible;
    max-width: 820px;
    width: fit-content;
  }
  .modal-card-body {
    max-width: 780px;
    width: fit-content;
  }
  .list {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .list-item {
    margin: 0;
  }
  #download-template {
    text-decoration: underline;
    color: hsl(207, 61%, 53%);
  }
</style>